label.SwitchIcon {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    box-sizing: border-box;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    text-align: center;
    flex: 0 0 auto;
    font-size: 1.5rem;
    padding: 8px;
    overflow: visible;
    color: rgb(25, 118, 210);
    /*transform: rotate(90deg);*/
    position: absolute;
    z-index: 23;
    right: -17px;
    top: auto;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #e0e3eb;
    margin-top: -128px;

}

@media (max-width: 992px){
    label.SwitchIcon {
    transform: rotate(90deg);
    right: 24px;
       margin-top: -241px;
}  
}
 
@media (max-width: 576px){
    label.SwitchIcon {
    transform: rotate(90deg);
    right: 24px;
       margin-top: -241px;
} 
.col-md-6.col-lg-3{
width: 50%;  
}
} 